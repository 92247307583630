import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import CustomLink from "../components/elements/custom-link"
import Breadcrumb from "../components/elements/breadcrumb"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useBlogCategories } from "../hooks/use-blog-categories"

const NewsPage = () => {
  const asmetHome = useAsmetHome()
  const allCategories = useBlogCategories()

  const crumbs = [{ path: `/sitemap`, label: "Mapa del Sitio" }]

  return (
    <Layout>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">
                Mapa del Sitio
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div id="asm-page-general" className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <ul>
              <li>
                <Link to="/" className="text-danger text-600">
                  <h5>Asmet Salud</h5>
                </Link>
              </li>
              <div className="mt-4"></div>
              {asmetHome.enlaces_principales.map((enlace, index) => {
                return (
                  <li key={index} className="py-1">
                    <CustomLink link={enlace}>
                      <ReactMarkdown
                        source={enlace.nombre}
                        renderers={{ paragraph: "span" }}
                      />
                    </CustomLink>
                  </li>
                )
              })}
              <div className="mt-4"></div>
              {asmetHome.menu_principal.map((menu, index) => {
                return menu.titulo.tipo === "Grupo" ? (
                  <li key={index} className="py-1">
                    <ReactMarkdown
                      source={menu.titulo.nombre}
                      renderers={{ paragraph: "span" }}
                    />
                    <ul>
                      {menu.links
                        ? menu.links.map((subLink, subIndex) => {
                            return (
                              <li key={subIndex} className="py-1">
                                <CustomLink link={subLink}>
                                  <ReactMarkdown
                                    source={subLink.nombre}
                                    renderers={{ paragraph: "span" }}
                                  />
                                </CustomLink>
                              </li>
                            )
                          })
                        : null}
                    </ul>
                  </li>
                ) : (
                  <li key={index} className="py-1">
                    <CustomLink link={menu.titulo}>
                      <ReactMarkdown
                        source={menu.titulo.nombre}
                        renderers={{ paragraph: "span" }}
                      />
                    </CustomLink>
                  </li>
                )
              })}
              <div className="mt-4"></div>
              <li>
                <Link to="/media">Multimedia</Link>
                <ul>
                  <li className="py-1">
                    <Link to="/fotos">Fotos</Link>
                  </li>
                  <li className="py-1">
                    <Link to="/audios">Audios</Link>
                  </li>
                  <li className="py-1">
                    <Link to="/videos">Videos</Link>
                  </li>
                </ul>
              </li>
              <div className="mt-4"></div>
              <li className="py-1">
                <Link to="/events">Eventos</Link>
              </li>
              <li className="py-1">
                <Link to="/noticias">Noticias</Link>
              </li>
              <div className="mt-4"></div>
              <li>
                <Link to="/blog">Blog</Link>
                <ul>
                  {allCategories.map((category, index) => {
                    return (
                      <li key={index} className="py-1">
                        <Link to={`/blog/${category.node.slug}`}>
                          <ReactMarkdown
                            source={category.node.nombre}
                            renderers={{ paragraph: "span" }}
                          />
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
              <div className="mt-4"></div>
              {asmetHome.footer.map((menu, index) => {
                return menu.titulo.tipo === "Grupo" ? (
                  <li key={index} className="py-1">
                    <ReactMarkdown
                      source={menu.titulo.nombre}
                      renderers={{ paragraph: "span" }}
                    />
                    <ul>
                      {menu.links
                        ? menu.links.map((subLink, subIndex) => {
                            return (
                              <li key={subIndex} className="py-1">
                                <CustomLink link={subLink}>
                                  <ReactMarkdown
                                    source={subLink.nombre}
                                    renderers={{ paragraph: "span" }}
                                  />
                                </CustomLink>
                              </li>
                            )
                          })
                        : null}
                    </ul>
                  </li>
                ) : (
                  <li key={index} className="py-1">
                    <CustomLink link={menu.titulo}>
                      <ReactMarkdown
                        source={menu.titulo.nombre}
                        renderers={{ paragraph: "span" }}
                      />
                    </CustomLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default NewsPage
